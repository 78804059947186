import { navigate } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { STRINGS } from '../../constants/strings';
import { URL } from '../../constants/urls';
import '../../css/components/_lead-generation.scss';
import { validateEmail } from '../../utils/articleUtil';

const GrouponForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [hasBooked, setHasBooked] = useState('');
  const [marketing, setMarketing] = useState(false);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const refs = {
    firstName: useRef(null),
    lastName: useRef(null),
    email: useRef(null),
    hasBooked: useRef(null),
    marketing: useRef(null),
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setFirstName(params.firstName || '');
    setLastName(params.lastName || '');
    setEmailAddress(params.email || '');
  }, []);

  const validateForm = () => {
    let errors = {};
    let firstErrorField = null;

    if (!firstName.trim()) {
      errors.firstName = 'Please enter your first name';
      if (!firstErrorField) firstErrorField = refs.firstName.current;
    }
    if (!lastName.trim()) {
      errors.lastName = 'Please enter your last name';
      if (!firstErrorField) firstErrorField = refs.lastName.current;
    }
    if (!emailAddress || !validateEmail(emailAddress)) {
      errors.email = 'Please enter a valid email address';
      if (!firstErrorField) firstErrorField = refs.email.current;
    }
    if (hasBooked === '') {
      errors.hasBooked = 'Please select if you have booked a trip with Groupon before';
      if (!firstErrorField) firstErrorField = refs.hasBooked.current;
    }
    if (!marketing) {
      errors.marketing = 'To receive updates, we need your consent';
      if (!firstErrorField) firstErrorField = refs.marketing.current;
    }

    setError(errors);

    if (firstErrorField && typeof firstErrorField.scrollIntoView === 'function') {
      firstErrorField.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      firstErrorField.focus();
    }

    return Object.keys(errors).length === 0;
  };

  const onActionClick = async () => {
    if (validateForm()) {
      setError({});
      setLoading(true);


      const hasBookedTag = hasBooked === 'yes' ? 'Groupon0824_YES' : 'Groupon0824_NO';
      const tags = `Groupon0824,${hasBookedTag}`;

      try {
        const response = await fetch(`${URL.SERVER}/subscribe?name=${firstName} ${lastName}&email=${emailAddress}&marketing=${marketing}&website=${STRINGS.SUBSCRIPTION_TAG}&tag=${tags}&list=GROUPON`);
        const result = await response.json();

        setLoading(false);
        setFirstName('');
        setLastName('');
        setEmailAddress('');
        setHasBooked('');
        setMarketing(false);

        navigate('/thankyou?returnUrl=/entertainment/culture/vue');
      } catch (error) {
        setLoading(false);
        console.error('Error:', error);
        setError({ submit: 'Submission failed. Please try again.' });
      }
    }
  };

  return (
    <div className="groupon-form">
      <div className="container">
        <h1>Subscribe and get 10% off Groupon Getaways and Local Deals</h1>
        {error.submit && <div className="text-red">{error.submit}</div>}
        <div className="input-container">
          <label htmlFor="firstName">First Name</label>
          <input
            id="firstName"
            type="text"
            placeholder="Enter your first name"
            value={firstName}
            onChange={(event) => {
              setError(prevError => ({ ...prevError, firstName: '' }));
              setFirstName(event.target.value);
            }}
            ref={refs.firstName}
          />
          {error.firstName && <div className="text-red">{error.firstName}</div>}
        </div>
        <div className="input-container">
          <label htmlFor="lastName">Last Name</label>
          <input
            id="lastName"
            type="text"
            placeholder="Enter your last name"
            value={lastName}
            onChange={(event) => {
              setError(prevError => ({ ...prevError, lastName: '' }));
              setLastName(event.target.value);
            }}
            ref={refs.lastName}
          />
          {error.lastName && <div className="text-red">{error.lastName}</div>}
        </div>
        <div className="input-container">
          <label htmlFor="email">Email Address</label>
          <input
            id="email"
            type="email"
            placeholder="Enter your email address"
            value={emailAddress}
            onChange={(event) => {
              setError(prevError => ({ ...prevError, email: '' }));
              setEmailAddress(event.target.value);
            }}
            ref={refs.email}
          />
          {error.email && <div className="text-red">{error.email}</div>}
        </div>
        <div className="input-container">
          <label>Have you booked a trip with Groupon before?</label>
          <div className="radio-group" ref={refs.hasBooked}>
            <label>
              <input
                type="radio"
                name="hasBooked"
                value="yes"
                checked={hasBooked === 'yes'}
                onChange={() => setHasBooked('yes')}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="hasBooked"
                value="no"
                checked={hasBooked === 'no'}
                onChange={() => setHasBooked('no')}
              />
              No
            </label>
          </div>
          {error.hasBooked && <div className="text-red">{error.hasBooked}</div>}
        </div>

        <div className="checkbox-container">
          <input
            type="checkbox"
            id="marketing"
            checked={marketing}
            onChange={() => setMarketing(!marketing)}
            ref={refs.marketing}
          />
          <label htmlFor="marketing">I’m happy for AgeGroup and Groupon to send me selected marketing information which may be of interest to me.</label>
        </div>
        {error.marketing && <div className="text-red -mt-3">{error.marketing}</div>}
        <div className="action-container mt-3">
          <button
            onClick={onActionClick}
            type="button"
            className="bg-red py-4 px-12 text-white rounded-[19px]"
          >
            Subscribe {loading && <img alt="loading" src="/images/loading.svg" style={{ marginLeft: '5px' }} />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default GrouponForm;
